<template>
  <v-app class="mainPage">
    <v-app-bar color="transparent" class="mt-2">
      <v-row align="center" no-gutters>
        <v-col class="ml-2 text-white"
          ><v-img
            :src="require('@/assets/icon.png')"
            max-width="64px"
            class=""
          ></v-img
        ></v-col>
        <v-col><b>Galeria de fotos</b></v-col>
        <v-spacer></v-spacer>
        <v-col
          class="d-flex text-white justify-center"
          xl="3"
          lg="3"
          md="3"
          sm="3"
        >
          <v-btn variant="outlined" @click="showFilter = true"
            ><v-icon>fas fa-search</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main class="mt-6">
      <v-container fluid>
        <v-row no-gutters justify-content="center">
          <v-col cols="8" sm="9" md="9" lg="9" xl="9">
            <v-container class="mt-n4">
              <v-row fluid no-gutters>
                <v-col
                  v-if="this.$data.selectedToten !== null"
                  cols="9"
                  class="text-white d-flex justify-start text-xl-h5 text-lg-h5 text-md-h5 text-sm-h5 text-xs-h5"
                >
                  <b
                    ><h5>
                      {{
                        `${this.$data.selectDate} - ${this.$data.selectCount} `
                      }}
                    </h5></b
                  >
                </v-col>
              </v-row>
              <v-row
                v-if="this.$data.selectedToten !== null"
                dense
                d-flex
                justify="center"
              >
                <v-col
                  v-for="listPhotos in listAllPhotos"
                  :key="listPhotos"
                  cols="4"
                  sm="3"
                  md="2"
                  lg="2"
                  xl="2"
                  class="pr-2 pb-2"
                >
                  <v-img
                    :src="listPhotos.photo_url"
                    aspect-radio="1/1"
                    conver
                    class="bg-grey-lighten-2"
                    @click="activePhoto(listPhotos)"
                    max-width="200px"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="dispatchGetData('down')"
              ><v-icon>fas fa-chevron-left</v-icon></v-btn
            >
            <v-btn @click="dispatchGetData('up')"
              ><v-icon>fas fa-chevron-right</v-icon></v-btn
            >
            <!-- v-pagination
              :length="Math.floor(this.$data.selectCount / this.$data.take)"
              rounded="circle"
              color="white"
              v-model="activePage"
              @next=""
              @prev=""
            ></!-->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-dialog v-model="showPhoto" max-width="420px">
      <v-card>
        <v-img :src="this.$data.selectPhoto.photo_url" class="align-end">
          <v-row>
            <v-col>
              <v-alert class="ma-2">
                <p>{{ selectPhoto ? selectPhoto.photo_lead_data.name : "" }}</p>
                <p>
                  <v-icon>fab fa-whatsapp</v-icon>
                  {{ selectPhoto ? selectPhoto.photo_lead_data.phone : "" }}
                </p>
              </v-alert>
            </v-col>
            <v-col cols="4">
              <v-img
                :src="this.$data.selectPhoto.photo_qr_code_url"
                class="qr_code_img mb-1 mr-1"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-img>
        <v-card-actions>
          <v-btn
            @click="downloadFile(this.$data.selectPhoto.photo_url)"
            icon="fa-solid fa-download"
          ></v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="this.showPhoto = false"
            icon="fa-solid fa-circle-xmark"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showFilter">
      <v-card width="50%">
        <v-container fluid>
          <v-row>
            <v-col
              ><v-select
                :items="campaigns"
                item-title="tag"
                item-value="id"
                label="Campanhas"
                v-model="selectedCampaign"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :subtitle="item.raw.name" />
                </template> </v-select
            ></v-col>
            <v-col
              ><v-select
                :items="arrayDates"
                label="Dia"
                v-model="selectDate"
                item-title="date_upload"
                item-value="date_upload"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :subtitle="item.raw.count" />
                </template> </v-select
            ></v-col>
          </v-row>
          <v-row justify="space-around"> </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="
              dispatchGetData();
              showFilter = false;
            "
            >Aplicar</v-btn
          >
          <v-btn @click="showFilter = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import { headers } from "@/components/lib/HttpTools.js";
import moment from "moment";

const apiLoadImages = "https://api.arxtotem.arxexperience.com.br";

export default {
  data: () => {
    return {
      activePage: "",
      amountPage: 1,
      searchDate: "",
      listAllPhotos: [],
      listAllDates: [],
      menuPhoto: false,
      selectPhoto: "",
      selectDate: moment(
        new Date().toISOString().split("T")[0],
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY"),
      selectCount: 0,
      initialDate: ["-", "0 fotos"],
      selectedNumberperPage: 4,
      totalPhotos: 0,
      arrayDates: [],
      listAllTotens: [],
      selectedCampaign: null,
      campaigns: [],
      campaignsName: [],
      showFilter: true,
      showPhoto: false,
      take: 10,
      skip: 0,
    };
  },
  methods: {
    activePhoto(photo) {
      this.$data.selectPhoto = photo;
      this.$data.showPhoto = true;
    },

    selectedDate(date) {
      this.$data.selectDate[1] = moment(date, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      this.setSelectDateIndexCount(this.$data.selectDate[1]);
    },

    getTimeNow() {
      const todayDate = moment(new Date()).format("YYYY-MM-DD");
      this.$data.initialDate[0] = moment(todayDate).format("DD/MM/YYYY");
      return todayDate;
    },
    /**
     * List Campaigns
     */
    getListOfCampaigns() {
      axios
        .get(
          `${apiLoadImages}/campaigns?customer_id=3cd16ac5-9212-430c-9f64-17a3bb633fec&tag=fortao`,
          { headers }
        )
        .then((response) => {
          this.$data.campaigns = response.data.result;
        });
    },
    dispatchGetData(direction = "") {
      if (direction == "up") {
        this.$data.skip = this.$data.skip + this.$data.take;
        // this.$data.activePage = this.$data.activePage + 1;
      } else if (direction == "down") {
        this.$data.skip = this.$data.skip - this.$data.take;
        // this.$data.activePage = this.$data.activePage - 1;
      }
      this.getListPhotos();
      this.getListofDates();
    },
    /**
     * GetListPhotos
     */
    getListPhotos() {
      axios
        .get(
          `${apiLoadImages}/galery?campaign_id=${
            this.$data.selectedCampaign
          }&take=10&skip=${this.$data.skip}&date=${moment(
            this.$data.selectDate,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD")}`,
          { headers }
        )
        .then((response) => {
          this.$data.listAllPhotos = response.data.list;
        })
        .catch((error) => console.error(error));
    },
    /**
     * Lista de datas
     */
    getListofDates() {
      this.$data.arrayDates = [];
      axios
        .get(`${apiLoadImages}/galery/count/${this.$data.selectedCampaign}`)
        .then((response) => {
          this.$data.listAllDates = response.data.count;
          this.$data.totalPhotos = response.data.total;
          this.$data.arrayDates = response.data.count;

          for (const date of this.$data.arrayDates) {
            date.date_upload = moment(
              new Date(date.date_upload).toISOString().split("T")[0]
            ).format("DD/MM/YYYY");
            if (date.date_upload == this.$data.selectDate) {
              this.$data.selectCount = date.count;
            }
          }
        });
    },
    /**
     * Download Photo
     * @param {*} photoUrl
     */
    downloadFile(photoUrl) {
      axios.get(photoUrl).then((response) => {
        var FILE = window.URL.createObjectURL(new Blob([response.data]));

        var docUrl = document.createElement("x");
        docUrl.href = FILE;
        docUrl.setAttribute("download", "file.jpeg");
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    formatDate(date) {
      const result = moment(date.split("T")[0]).format("DD/MM/YYYY");
      return result;
    },
    setSelectDateIndexCount(date) {
      if (
        this.$data.listAllDates.findIndex((el) => {
          return el.date_upload === `${date}T00:00:00.000Z`;
        }) > -1
      ) {
        this.$data.selectDate[0] = this.$data.listAllDates.findIndex((el) => {
          return el.date_upload === `${date}T00:00:00.000Z`;
        });
        this.$data.selectDate[2] =
          this.$data.listAllDates[this.$data.selectDate[0]].count;
        this.loadPhotos(
          0,
          this.$data.selectedNumberperPage,
          this.$data.selectDate[1]
        );
      } else {
        this.$data.initialDate[1] = "0 fotos";
        this.$data.amountPage = 1;
        this.$data.listAllPhotos = [];
        this.$data.searchDate = "";
      }
    },
  },
  mounted() {
    this.getListOfCampaigns();
  },
};
</script>
<style>
.qr_code_img {
  opacity: 0.8;
}

.mainPage {
  min-height: 910px;
}
</style>
