<template>
  <PhotoGallery />
</template>

<script>
// @ is an alias to /src
import PhotoGallery from "@/components/PhotoGallery.vue";

export default {
  name: "PhotosViewer",
  components: {
    PhotoGallery,
  },
};
</script>
